import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import EventBus from '../../EventBus';
import { ServerResponseCode } from "../../Networking";
import { ActiveCharts } from '../../RealTimeSubscription';
import { chart_types, getOHLCAndVolumeFromData } from "../Charts/PeriodChart";

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { format } from "d3-format";
//import { userHasFullAccess } from '../../AuthenticationUtil';

const chartStepTitleMap = {
    "t1min": 'M1',
    "t2min": 'M2',
    "t3min": 'M3',
    "t4min": 'M4',
    "t5min": 'M5',
    "t10min": 'M10',
    "t15min": 'M15',
    "t20min": 'M20',
    "t25min": 'M25',
    "t30min": 'M30',
    "t45min": 'M45',
    "t1h": 'H1',
    "t90min": 'H1.5',
    "t2h": 'H2',
    "t3h": 'H3',
    "t4h": 'H4',
    "t5h": 'H5',
    "t6h": 'H6',
    "t7h": 'H7',
    "t8h": 'H8',
    "t9h": 'H9',
    "t10h": 'H10',
    "t11h": 'H11',
    "t12h": 'H12',
    "t13h": 'H13',
    "t14h": 'H14',
    "t15h": 'H15',
    "t16h": 'H16',
    "t17h": 'H17',
    "t18h": 'H18',
    "t19h": 'H19',
    "t20h": 'H20',
    "t21h": 'H21',
    "t22h": 'H22',
    "t23h": 'H23',
    "t1day": 'D1',
    "t36h": 'D1.5',
    "t2day": 'D2',
    "t60h": 'D2.5',
    "t3day": 'D3',
    "t4day": 'D4',
    "t5day": 'D5',
    "t1week": 'W1',
    "t6day": 'D6',
    "t7day": 'D7',
    "t8day": 'D8',
    "t9day": 'D9',
    "t10day": 'D10',
    "t11day": 'D11',
    "t12day": 'D12',
    "t13day": 'D13',
    "t14day": 'D14',
    "t15day": 'D15',
    "t16day": 'D16',
    "t17day": 'D17',
    "t18day": 'D18',
    "t19day": 'D19',
    "t20day": 'D20',
    "t21day": 'D21',
    "t22day": 'D22',
    "t23day": 'D23',
    "t24day": 'D24',
    "t25day": 'D25',
    "t26day": 'D26',
    "t27day": 'D27',
    "t28day": 'D28',
    "t29day": 'D29',
    "t30day": 'D30',
    "t1m": 'MN1',
    "t45day": 'MN1.5',
    "t60day": 'MN2',
    "t75day": 'MN2.5',
    "t3m": 'MN3',
    "t120day": 'MN4',
    "t150day": 'MN5',
    "t6m": 'MN6',
    "t210day": 'MN7',
    "t240day": 'MN8',
    "t270day": 'MN9',
    "t300day": 'MN10',
    "t330day": 'MN11',
    "t1y": 'Y1',
    "t18m": 'Y1.5',
    "t2y": 'Y2',
};

class Offsidebar extends Component {

    state = {
        activeTab: 'mtfp',
        offsidebarReady: false,

        mtfp_symbol: null,
        mtfp_isForexSymbol: false,
        mtfp_currentPrice: null,
        mtfp_timeframes: [],
    }

    componentDidMount() {
        // When mounted display the offsidebar
        this.setState({ offsidebarReady: true });
        EventBus.on("symbolChanged", this.onSymbolChanged);
        EventBus.on("realTimeUpdate", this.onRealTimeUpdate);
    }

    onSymbolChanged = (data) => {
        if (data && this.state.mtfp_symbol !== data.quote) {
            const timeframes = [ ...ActiveCharts ]
                .filter((_, index) => data.category !== "EquityNonUS" || index >= 32)
                .map(chartData => ({
                  id: chartData.step,
                  title: chartStepTitleMap[chartData.step], 
                  trendStrength: null,
                  mfi: null,
              }));
            this.setState({
                mtfp_symbol: data.quote,
                mtfp_isForexSymbol: data.category === "Forex",
                mtfp_currentPrice: null,
                mtfp_timeframes: timeframes,
            });
        }
    };

    onRealTimeUpdate = (data) => {
        if (data && data.step && data.response && data.response.code != ServerResponseCode.NEW_STEP && data.response.data) {
            const { mtfp_timeframes, mtfp_currentPrice } = this.state;
            const chartId = chart_types[data.step];
            const ohlcv = getOHLCAndVolumeFromData(data.response.data);
            const newTimeframes = [ ...mtfp_timeframes ];
            let updateNeeded = false;

            const timeframeIndex = mtfp_timeframes.findIndex(el => el.id === chartId);
            if (timeframeIndex != -1) {   
                if (newTimeframes[timeframeIndex].trendStrength != ohlcv.trendStrength[0] || newTimeframes[timeframeIndex].mfi != ohlcv.mfi) {
                    newTimeframes[timeframeIndex].trendStrength = ohlcv.trendStrength[0];
                    newTimeframes[timeframeIndex].mfi = ohlcv.mfi;
                    updateNeeded = true;
                }
            }
            if (!updateNeeded && data.step === 4 && mtfp_currentPrice != ohlcv.close) {
                updateNeeded = true;
            }
            if (updateNeeded)
                this.setState({
                    mtfp_currentPrice: data.step === 4 ? ohlcv.close : mtfp_currentPrice,
                    mtfp_timeframes: newTimeframes,
                });
        }
    };

    componentWillUnmount() {
        EventBus.remove("symbolChanged", this.onSymbolChanged);
        EventBus.remove("realTimeUpdate", this.onRealTimeUpdate);
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleSettingCheckbox = event => {
        this.props.actions.changeSetting(event.target.name, event.target.checked);
    }

    handleThemeRadio = event => {
        this.props.actions.changeTheme(event.target.value);
    }

    render() {
        const { mtfp_symbol, mtfp_isForexSymbol, mtfp_currentPrice, mtfp_timeframes, } = this.state;
        const decimalValueFormatter = format(!mtfp_isForexSymbol ? '.2f' : '.5f');
        const trendStrengthColorUp = "#006517";
        const trendStrengthColorDown = "#fe0606";

        const timeframesFirstColumn = mtfp_timeframes.filter((_, index) => index % 2 == 0);
        const timeframesSecondColumn = mtfp_timeframes.filter((_, index) => index % 2 == 1);

        const elementGenerationLambda = (el, index) => {
            let trendStrengthColor;
            if (el.trendStrength > 0) {
                trendStrengthColor = trendStrengthColorUp;
            } else if (el.trendStrength < 0) {
                trendStrengthColor = trendStrengthColorDown;
            } else {
                trendStrengthColor = "#909FA7";
            }
            return (
                <div key={index} style={{display: "block", padding: "10px", position: "relative", borderBottom: index != 0 && (index + 1) % 5 == 0 ? "1px solid #E1E1E1" : 'auto'}}>
                    <div className="media" style={{alignItems: 'center'}}>
                        <div className="media-body text-truncate">
                            <strong style={{fontWeight: 'bold', fontSize: 14}}>{el.title}</strong>
                            <br/>
                            <span style={{color: trendStrengthColor, fontSize: 14}}>{el.trendStrength !== null && el.trendStrength !== undefined ? decimalValueFormatter(el.trendStrength) : 'N/A'}</span>
                            { el.trendStrength !== undefined && el.trendStrength !== null && el.trendStrength !== 0 &&
                                <img src={ el.trendStrength > 0 ? "icons/green-arrow-up.svg" : "icons/red-arrow-down.svg" } alt="" style={{width: 16, height: 16, position: 'absolute', right: '30px'}} />
                            }
                            { el.mfi !== undefined && el.mfi !== null &&
                                <img src={ el.mfi >= 50 ? "icons/mfi-up.png" : "icons/mfi-down.png" } alt="" style={{width: 18, height: 18, position: 'absolute', right: '6px', bottom: '12px'}} />
                            }
                        </div>
                    </div>
                </div>
            );
        };

        const timeframesContainerAdditionalStyle = /*userHasFullAccess()*/ true ? {} : {
            'WebkitFilter': 'blur(5px)',
            'MozFilter': 'blur(5px)',
            'OFilter': 'blur(5px)',
            'msFilter': 'blur(5px)',
            'filter': 'blur(5px)',
            'WebkitUserSelect': 'none',
            'msUserSelect': 'none',
            'userSelect': 'none',
        };

        return (
            this.state.offsidebarReady &&
            <aside className="offsidebar">
                { /* START Off Sidebar (right) */ }
                <nav>
                    <div>
                        { /* Nav tabs */ }
                        <Nav tabs justified>
                            <NavItem>
                                <NavLink className={ this.state.activeTab === 'mtfp' ? 'active':'' }
                                    onClick={() => { this.toggle('mtfp'); }}
                                >
                                    <em className="icon-graph fa-lg"></em>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={ this.state.activeTab === 'chat' ? 'active':'' }
                                    onClick={() => { return; /* currently disabled */ this.toggle('chat'); }}
                                >
                                    <em className="icon-info fa-lg"></em>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        { /* Tab panes */ }
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="mtfp">
                                <h3 className="text-center text-thin mt-4" style={{marginBottom: 5}}>{ mtfp_symbol ? mtfp_symbol : 'N/A' }</h3>
                                <div className="list-group-item border-0" style={{textAlign: 'center', paddingTop: 0}}>
                                    <span className="text-muted" style={{fontSize: 14}}>{ mtfp_currentPrice !== undefined && mtfp_currentPrice !== null ? decimalValueFormatter(mtfp_currentPrice) : 'N/A' }</span>
                                </div>
                                <div className="list-group" style={{display: 'flex', flexDirection: 'row', ...timeframesContainerAdditionalStyle}}>
                                    <div className="list-group-lhs" style={{display: 'flex', flexGrow: '1', flexDirection: 'column'}}>
                                    {
                                        timeframesFirstColumn.map(elementGenerationLambda)
                                    }
                                    </div>
                                    <div className="list-group-rhs" style={{display: 'flex', flexGrow: '1', flexDirection: 'column'}}>
                                    {
                                        timeframesSecondColumn.map(elementGenerationLambda)
                                    }
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="chat">
                                <h3 className="text-center text-thin mt-4">Connections</h3>
                                <div className="list-group">
                                    { /* START list title */ }
                                    <div className="list-group-item border-0">
                                        <small className="text-muted">ONLINE</small>
                                    </div>
                                    { /* END list title */ }
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/05.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Juan Sims</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-success circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/06.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Maureen Jenkins</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-success circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/07.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Billie Dunn</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-danger circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/08.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Tomothy Roberts</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-warning circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    { /* START list title */ }
                                    <div className="list-group-item border-0">
                                        <small className="text-muted">OFFLINE</small>
                                    </div>
                                    { /* END list title */ }
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/09.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Lawrence Robinson</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-warning circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item list-group-item-action border-0">
                                        <div className="media">
                                            <img className="align-self-center mr-3 rounded-circle thumb48" src="img/user/10.jpg" alt="User avatar" />
                                            <div className="media-body text-truncate">
                                                <a href="">
                                                    <strong>Tyrone Owens</strong>
                                                </a>
                                                <br/>
                                                <small className="text-muted">Designeer</small>
                                            </div>
                                            <div className="ml-auto">
                                                <span className="circle bg-warning circle-lg"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3 py-4 text-center">
                                    { /* Optional link to list more users */ }
                                    <a className="btn btn-purple btn-sm" href="" title="See more contacts">
                                        <strong>Load more..</strong>
                                    </a>
                                </div>
                                { /* Extra items */ }
                                <div className="px-3 py-2">
                                    <p>
                                        <small className="text-muted">Tasks completion</small>
                                    </p>
                                    <div className="progress progress-xs m-0">
                                        <div className="progress-bar bg-success" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: '80%'}}>
                                            <span className="sr-only">80% Complete</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3 py-2">
                                    <p>
                                        <small className="text-muted">Upload quota</small>
                                    </p>
                                    <div className="progress progress-xs m-0">
                                        <div className="progress-bar bg-warning" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width: '40%'}}>
                                            <span className="sr-only">40% Complete</span>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            {/*<TabPane tabId="settings">
                                <h3 className="text-center text-thin mt-4">Settings</h3>
                                <div className="p-2">
                                    <h4 className="text-muted text-thin">Themes</h4>
                                    <div className="row row-flush mb-2">
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-a.css'}  value='themes/theme-a.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-info"></span>
                                                        <span className="color bg-info-light"></span>
                                                    </span>
                                                    <span className="color bg-white"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-b.css'}  value='themes/theme-b.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-green"></span>
                                                        <span className="color bg-green-light"></span>
                                                    </span>
                                                    <span className="color bg-white"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-c.css'}  value='themes/theme-c.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-purple"></span>
                                                        <span className="color bg-purple-light"></span>
                                                    </span>
                                                    <span className="color bg-white"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-d.css'}  value='themes/theme-d.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-danger"></span>
                                                        <span className="color bg-danger-light"></span>
                                                    </span>
                                                    <span className="color bg-white"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-e.css'}  value='themes/theme-e.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-info-dark"></span>
                                                        <span className="color bg-info"></span>
                                                    </span>
                                                    <span className="color bg-gray-dark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-f.css'}  value='themes/theme-f.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-green-dark"></span>
                                                        <span className="color bg-green"></span>
                                                    </span>
                                                    <span className="color bg-gray-dark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-g.css'}  value='themes/theme-g.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-purple-dark"></span>
                                                        <span className="color bg-purple"></span>
                                                    </span>
                                                    <span className="color bg-gray-dark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3 mb-3">
                                            <div className="setting-color">
                                                <label>
                                                    <input type="radio" name="setting-theme" checked={this.props.theme.path === 'themes/theme-h.css'}  value='themes/theme-h.css' onChange={this.handleThemeRadio} />
                                                    <span className="icon-check"></span>
                                                    <span className="split">
                                                        <span className="color bg-danger-dark"></span>
                                                        <span className="color bg-danger"></span>
                                                    </span>
                                                    <span className="color bg-gray-dark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <h4 className="text-muted text-thin">Layout</h4>
                                    <div className="clearfix">
                                        <p className="float-left">Fixed</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-fixed" type="checkbox" name="isFixed" checked={this.props.settings.isFixed} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <p className="float-left">Boxed</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-boxed" type="checkbox" name="isBoxed" checked={this.props.settings.isBoxed} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <h4 className="text-muted text-thin">Aside</h4>
                                    <div className="clearfix">
                                        <p className="float-left">Collapsed</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-collapsed" type="checkbox" name="isCollapsed" checked={this.props.settings.isCollapsed} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <p className="float-left">Collapsed Text</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-collapsed-text" type="checkbox" name="isCollapsedText" checked={this.props.settings.isCollapsedText} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <p className="float-left">Float</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-float" type="checkbox" name="isFloat" checked={this.props.settings.isFloat} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <p className="float-left">Hover</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-hover" type="checkbox" name="asideHover" checked={this.props.settings.asideHover} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <p className="float-left">Show Scrollbar</p>
                                        <div className="float-right">
                                            <label className="switch">
                                                <input id="chk-scrollbar" type="checkbox" name="asideScrollbar" checked={this.props.settings.asideScrollbar} onChange={this.handleSettingCheckbox}/>
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>*/}
                        </TabContent>
                    </div>
                </nav>
                { /* END Off Sidebar (right) */ }
            </aside>
        );
    }

}

Offsidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    theme: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, theme: state.theme })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Offsidebar);
