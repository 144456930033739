import client from "./Networking";
import { periods } from "./components/Dashboard/PeriodsCommon";
import { chart_types, indicators } from "./components/Charts/PeriodChart";
import EventBus from "./EventBus";

export const FREE_TIER_UPDATE_THRESHOLD = 20 * 60; // 20 minutes, in seconds

export const ActiveCharts = [
  ...periods.IntraDay1.charts,
  ...periods.IntraDay2.charts,
  ...periods.IntraDay3.charts,
  ...periods.IntraDay4.charts,
  ...periods.ShortTerm.charts,
  ...periods.MidTerm.charts,
  ...periods.LongTerm1.charts,
  ...periods.LongTerm2.charts,
  ...periods.LongTerm3.charts,
];

const SUBSCRIPTION_INITIALIZATION_DELAY = 500;

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

const getRealTimeUpdateHandler = (chartIndex) => {
  return (response) => {
    EventBus.dispatch("realTimeUpdate", { ...subscriptions[chartIndex], response });
  };
};

let subscriptions = [];

let initializedForSymbol = null;
let initializedIndex = 0;

const RealTimeSubscription = {

  initialize: async (symbol) => {
    if (!symbol || symbol === initializedForSymbol)
      return;
    initializedForSymbol = symbol;
    initializedIndex++;
    const thisExecutionInitializedForSymbol = initializedForSymbol;
    const thisExecutionInitializedIndex = initializedIndex;
    for (let i = 0, len = ActiveCharts.length; i < len; ++i) {
      if (initializedIndex !== thisExecutionInitializedIndex || initializedForSymbol !== thisExecutionInitializedForSymbol) break;  // symbol switch has happened, stop initializing the previous transactions
      const chartInfo = ActiveCharts[i];
      const { step } = chartInfo;
      const actualStep = chart_types.indexOf(step);
      let { id } = client.subscribe(symbol, actualStep, indicators, getRealTimeUpdateHandler(i));
      subscriptions[i] = { id, symbol, step: actualStep, indicators };
      await sleep(SUBSCRIPTION_INITIALIZATION_DELAY);
    }
  },
  
  terminate: async () => {
    subscriptions.forEach(subscription => {
      client.cancel(subscription.id);
    });
    subscriptions = [];
    initializedForSymbol = null;
  }

};

export default RealTimeSubscription;